import { getTitle } from '@/helpers'
export class PinterestService {
  constructor(pinterestId) {
    this.pinterestId = pinterestId

    this.initPinterest(pinterestId)
  }

  initPinterest() {
    !(function (e) {
      if (!window.pintrk) {
        window.pintrk = function () {
          window.pintrk.queue.push(Array.prototype.slice.call(arguments))
        }

        var n = window.pintrk
        ;(n.queue = []), (n.version = '3.0')

        var t = document.createElement('script')
        ;(t.async = !0), (t.src = e)
        var r = document.getElementsByTagName('script')[0]
        r.parentNode.insertBefore(t, r)
      }
    })('https://s.pinimg.com/ct/core.js')
    // <!-- end Pinterest Tag -->

    this.pintrk = window.pintrk || []

    this.loadPintrk()
  }

  async loadPintrk() {
    await this.pintrk('load', this.pinterestId)

    this.page()
  }

  page() {
    this.pintrk('page')
  }

  addToCart({ product_id, value, order_quantity, currency }) {
    this.pintrk('track', 'addtocart', {
      product_id,
      value,
      order_quantity,
      currency,
    })
  }

  pageVisit(product_id = null) {
    if (!product_id) {
      this.pintrk('track', 'pagevisit')
      return
    }

    this.pintrk('track', 'pageVisit', {
      product_id: [product_id],
    })
  }

  signUp() {
    this.pintrk('track', 'signup')
  }

  search(search_query) {
    this.pintrk('track', 'search', { search_query })
  }

  viewCategory({ id, title }) {
    this.pintrk('track', 'viewcategory', {
      line_items: [getTitle(title)],
    })
  }
}
