import gql from "graphql-tag";

export const createCustomer = gql`
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const createCustomerAccessToken = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const getCustomerData = gql`
  query customer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
    }
  }
`;

export const sendPasswordResetEmail = gql`
  mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const resetCustomerPassword = gql`
  mutation customerReset($id: ID!, $input: CustomerResetInput!) {
    customerReset(id: $id, input: $input) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const getCustomerOrders = gql`
  query customer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      orders(first: 100) {
        edges {
          node {
            id
            orderNumber
            fulfillmentStatus
            financialStatus
            processedAt
            totalPriceV2 {
              amount
            }
          }
        }
      }
    }
  }
`;

export const getOrderById = gql`
  query order($id: ID!) {
    node(id: $id) {
      ... on Order {
        id
        orderNumber
        fulfillmentStatus
        successfulFulfillments(first: 100) {
          trackingCompany
          trackingInfo {
            number
            url
          }
        }
        financialStatus
        currencyCode
        processedAt
        totalPriceV2 {
          amount
        }
        subtotalPriceV2 {
          amount
        }
        totalTaxV2 {
          amount
        }
        totalShippingPriceV2 {
          amount
        }
        shippingAddress {
          firstName
          lastName
          address1
          address2
          city
          province
          zip
          country
        }
        discountApplications(first: 100) {
          edges {
            node {
              value
              allocationMethod
              targetType
              targetSelection
            }
          }
        }
        lineItems(first: 100) {
          edges {
            node {
              title
              quantity
              originalTotalPrice {
                amount
              }
              variant {
                title
                id
                sku
                price {
                  amount
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getOrderPaymentDetails = gql`
  query payment($id: ID!) {
    node(id: $id) {
      id
    }
  }
`;

export const getCustomerAddressess = gql`
  query customer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      defaultAddress {
        id
        firstName
        lastName
        address1
        address2
        city
        country
        countryCodeV2
        province
        provinceCode
        zip
        phone
      }
      addresses(first: 5) {
        edges {
          node {
            id
            firstName
            lastName
            address1
            address2
            city
            country
            countryCodeV2
            province
            provinceCode
            zip
            phone
          }
        }
      }
    }
  }
`;

export const createNewAddress = gql`
  mutation customerAddressCreate(
    $customerAccessToken: String!
    $address: MailingAddressInput!
  ) {
    customerAddressCreate(
      customerAccessToken: $customerAccessToken
      address: $address
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const updateAddress = gql`
  mutation customerAddressUpdate(
    $customerAccessToken: String!
    $id: ID!
    $address: MailingAddressInput!
  ) {
    customerAddressUpdate(
      customerAccessToken: $customerAccessToken
      id: $id
      address: $address
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const updateDefaultAddress = gql`
  mutation customerDefaultAddressUpdate(
    $customerAccessToken: String!
    $addressId: ID!
  ) {
    customerDefaultAddressUpdate(
      customerAccessToken: $customerAccessToken
      addressId: $addressId
    ) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const deleteAddress = gql`
  mutation customerAddressDelete($id: ID!, $customerAccessToken: String!) {
    customerAddressDelete(id: $id, customerAccessToken: $customerAccessToken) {
      deletedCustomerAddressId
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const updateCustomerProfile = gql`
  mutation customerUpdate(
    $customerAccessToken: String!
    $customer: CustomerUpdateInput!
  ) {
    customerUpdate(
      customerAccessToken: $customerAccessToken
      customer: $customer
    ) {
      customer {
        id
        firstName
        lastName
        email
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const customerActivate = gql`
  mutation customerActivate($id: ID!, $input: CustomerActivateInput!) {
    customerActivate(id: $id, input: $input) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;
