import gql from 'graphql-tag';

const CheckoutFragment = gql`
  fragment CheckoutFragment on Checkout {
    id
    webUrl
    totalTax {
      amount
    }
    subtotalPrice {
      amount
    }
    totalPrice {
      amount
    }
    lineItems (first: 250) {
      edges {
        node {
          id
          title
          variant {
            id
            title
            image {
              src
            }
            price {
              amount
            }
          }
          quantity
          discountAllocations {
            allocatedAmount {
              ... on MoneyV2 {
                amount
              }
            }
            discountApplication {
              ... on DiscountCodeApplication {
                allocationMethod
                targetSelection
                targetType
                value {
                  ... on MoneyV2 {
                    amount
                  }
                }
              }
            }
          }
        }
      }
    }
    discountApplications(first: 10) {
      edges {
        node {
          ... on DiscountCodeApplication {
            allocationMethod
            targetSelection
            targetType
            value {
              ... on MoneyV2 {
                amount
              }
            }
          }
        }
      }
    }
  }
`;

// discountApplication {
//   edges {
//     node {
//       allocationMethod
//       targetSelection
//       targetType
//     }
//   }
// }

export const findCheckout = gql`
  query ($checkoutId: ID!){
    node(id: $checkoutId) {
      ...on Checkout {
        completedAt
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const createCheckout = gql`
  mutation checkoutCreate ($input: CheckoutCreateInput!){
    checkoutCreate(input: $input) {
      userErrors {
        message
        field
      }
      checkout {
        completedAt
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const checkoutLineItemsReplace = gql`
  mutation checkoutLineItemsReplace ($lineItems: [CheckoutLineItemInput!]!, $checkoutId: ID!) {
    checkoutLineItemsReplace(lineItems: $lineItems, checkoutId: $checkoutId) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const checkoutLineItemsUpdate = gql`
  mutation checkoutLineItemsUpdate ($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const checkoutLineItemsRemove = gql`
  mutation checkoutLineItemsRemove ($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const updateCheckoutAttributes = gql`
    mutation checkoutAttributesUpdateV2($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
        checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
            checkout {
                id
            }
            checkoutUserErrors {
                code
                field
                message
            }
        }
    }
`

export const applyDiscountCode = gql`
mutation checkoutDiscountCodeApplyV2($discountCode: String!, $checkoutId: ID!) {
  checkoutDiscountCodeApplyV2(
    discountCode: $discountCode
    checkoutId: $checkoutId
  ) {
    checkout {
      id
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}
`
