import { sanitizeShopifyId } from "@/helpers";
export class FacebookService {
  constructor(facebookID) {
    this.facebookID = facebookID

    this.initFacebook();
  }

  loadScript() {
    if (!process.client) return null;

    !(function(f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function() {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
  }

  initFacebook() {
    this.loadScript();

    this.facebook = window.fbq || [];

    this.init();
    this.pageView();
  }

  init() {
    this.facebook("init", this.facebookID);
  }

  pageView() {
    this.facebook("track", "PageView");
  }

  contentView() {
    this.facebook("track", "ContentView");
  }

  addToCart({ content_ids, content_name, content_type, value, currency }) {
    this.facebook("track", "AddToCart", {
      content_ids,
      content_name,
      content_type,
      value,
      currency
    });
  }

  initiateCheckout({ value, lineItems, currency }) {
    try {
      this.facebook("track", "InitiateCheckout", {
        value,
        currency,
        content_ids: this.getContentIds(lineItems),
        num_items: this.getLineItemQuantity(lineItems)
      });
    } catch (error) {
      console.log(error)
    }
  }

  search(search_string) {
    this.facebook("track", "Search", { search_string });
  }

  getContentIds(lineItems) {
    return lineItems.map(({ node }) => sanitizeShopifyId("Product", node.id));
  }

  getLineItemQuantity(lineItems) {
    return lineItems.reduce((item, acc) => acc + item.quantity, 0);
  }
}
