import { store } from '../store'

import { getTitle } from '@/helpers'

import { getGQLRespObject } from './shopLandingService'

import {
  fetchHighlightByUIDForFilter,
  fetchConcernsForFilters,
  fetchConcernByUIDForFilter,
} from '../prismic/queries/landingPage'

import { prismicClient } from '../plugins/apollo/prismic'

export const buildFilters = async ({
                                     products,
                                     isMakeup,
                                     isProductType,
                                     shopCategory,
                                   }) => {
  const productBasedFilters = findProductBasedFilters(
    products,
    isMakeup,
    isProductType
  )

  const filters = {
    ...productBasedFilters,
    /*concerns: {
      key: 'concerns',
      title: 'Concerns',
      values: !isMakeup ? await getConcerns() : [],
    },*/
    highlights: {
      title: 'Highlights',
      key: 'highlights',
      values: getShopCategoryHighlights(shopCategory),
    },
  }

  return removeEmptyFilters(filters)
}

export const findProductBasedFilters = (products, isMakeup, isProductType) => ({
  productTypes: {
    key: 'product_types',
    title: 'Categories',
    values: !isProductType ? getFilterValues(products, 'product_type') : [],
  },
  brands: {
    key: 'brands',
    title: 'Brands',
    values: !isMakeup ? getFilterValues(products, 'brand') : [],
  },
})

export const removeEmptyFilters = filters => {
  return Object.keys(filters)
    .filter(key => filters[key].values.length)
    .map(key => filters[key])
}

/*******************
 *
 * @getFilterValues
 * takes an array, removes any duplicate values
 * then sorts the remaining values alphabetically
 *
 * @Array array of products
 * @type string -- ie 'product_type' or 'brand',
 *
 *******************/

export const getFilterValues = (array, type) => {
  const options = getUniqueValues(array, type)

  if (options.length <= 1) return []

  return sortAlphabetically(options, 'title')
}

const sortAlphabetically = (array, key) => {
  return array.sort((a, b) => {
    const aAttr = getTitle(a[key])
    const bAttr = getTitle(b[key])

    if (aAttr < bAttr) return -1

    if (aAttr > bAttr) return 1

    return 0
  })
}

/***********
 * getConcerns()
 *
 * populates the concerns dropdown filter.
 * first checks vuex if concerns are already stored,
 * otherwise it queries prismic and returns the results
 *
 //************/
const getConcerns = async () => {
  const { concerns } = store.state.filters

  if (concerns) return concerns

  const data = await fetchConcerns()
  store.dispatch('setConcerns', data).filters

  return data ?? []
}

/***********
 * fetchConcerns()
 *
 * makea a request to Prismic
 * for all the concerns on the backend.
 *
 //************/
const fetchConcerns = async () => {
  const { data } = await prismicClient.query({ query: fetchConcernsForFilters })

  const { edges } = getGQLRespObject(data)

  return edges.map(({ node }) => node)
}

/***********
 * getShopCategoryHighlights()
 *
 * Looks at Vuex, and returns all the
 * Highlight Collections inside of the Shop Category
 *
 /************/
const getShopCategoryHighlights = shopCat => {
  if (!shopCat) return []

  return shopCat.highlights
    .filter(({ collection }) => collection.title[0].text === 'Best Sellers')
    .map(({ collection }) => collection)

  /*
  return shopCat.highlights
    .filter(({ collection }) => collection.__typename === 'Collection')
    .map(({ collection }) => collection)
   */
}

/***********
 * getUniqueValues()
 *
 * maps over an array of products and returns only the unique values
 * unique brands
 * unique product types
 *
 /************/
export const getUniqueValues = (array, key) => {
  const results = []
  const map = new Map()

  array.forEach(product => {
    const prod = product.node ?? product
    const value = prod[key]

    if (value && !map.has(value._meta.id)) {
      map.set(value._meta.id, true)
      results.push(value)
    }
  })

  return results
}

/***********
 * filterProducts()
 *
 * filters products by various conditions
 *
 /************/
export const filterProducts = (filters, products) => {
  try {
    /*
    const filteredProducts = filterByConcern(products, filters.concerns)
      .then(prods => filterByHighlight(prods, filters.highlights))
      .then(prods => filterProductByAttributes('brand', prods, filters.brands))
      .then(prods =>
        filterProductByAttributes('product_type', prods, filters.product_types)
      )
      */

    const filteredProducts = filterByHighlight(products, filters.highlights)
      .then(prods => filterProductByAttributes('brand', prods, filters.brands))
      .then(prods =>
        filterProductByAttributes('product_type', prods, filters.product_types)
      )

    return filteredProducts
  } catch (error) {
    console.log(error)
    return products
  }
}

/***********
 * filterByConcern()
 *
 * fetchs all the product in the specific concern
 * compares them against the current products
 *
 /************/
const filterByConcern = (products, concerns) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!concerns.length) return resolve(products)

      const concernProducts = await fetchConcernProducts(concerns[0])
      const filteredProducts = findProductsInBothArrays(
        products,
        concernProducts
      )

      return resolve(filteredProducts)
    } catch (error) {
      console.log(error)
      return reject([])
    }
  })
}

/***********
 * fetchConcernProducts()
 *
 * makes a request to prismic for all
 * the products in a concern
 *
 /************/
const fetchConcernProducts = async uid => {
  try {
    const { data } = await prismicClient.query({
      query: fetchConcernByUIDForFilter,
      variables: { uid },
    })

    const concern = data.allConcerns.edges[0].node
    return getProductsFromConcern(concern)
  } catch (error) {
    console.log(error)
    return []
  }
}

/***********
 * getProductsFromConcern()
 *
 * extracts all the products from the
 * various fields inside of a concern
 *
 /************/
const getProductsFromConcern = concern => {
  const features = concern.features?.map(({ products }) =>
    products.map(prod => prod.product)
  )

  const callouts = concern.callout_collection?.products.map(
    prod => prod.product
  )

  const featured_products = concern.featured_products?.map(prod => prod.product)

  const collections = concern.featured_collections?.map(({ products }) =>
    products.map(prod => prod.product)
  )

  return [features, callouts, featured_products, collections]
    .flat()
    .filter(v => v != null)
}

/***********
 * filterByHighlight()
 *
 * gets the highlight products from Prismic,
 * compares them to the existing array of products
 *
 /************/
const filterByHighlight = (products, highlights) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!highlights.length) return resolve(products)


      console.log(highlights);

      const highlightProducts = await fetchHighlightProducts(highlights[0])
      const filteredProducts = findProductsInBothArrays(
        products,
        highlightProducts
      )

      return resolve(filteredProducts)
    } catch (error) {
      console.log(error)
      return reject([])
    }
  })
}

/***********
 * fetchHighlightProducts()
 *
 * makes the request to prismic for all
 * the products in the highlight/collection
 *
 /************/
const fetchHighlightProducts = async uid => {
  try {
    const { data } = await prismicClient.query({
      query: fetchHighlightByUIDForFilter,
      variables: { uid },
    })
    const collection = data.allCollections.edges[0].node
    return collection.products.map(prod => prod.product)
  } catch (error) {
    console.log(error)
    return []
  }
}

/***********
 * filterProductByAttributes()
 *
 * product attributes are fields available on the
 * product model -- i.e. brand & product_type
 *
 * filterValuess: array of attributes to check against
 * i.e. ['dr-sebagh', 'skinceuticals']
 *
 /************/
const filterProductByAttributes = (key, products, filterValues) => {
  if (!filterValues.length) return products

  return products.filter(prod => {
    const product = prod.node ?? prod

    return filterValues.includes(product[key]?._meta?.uid)
  })
}

/***********
 * appendParams()
 *
 * Builds a new query string and then overwrites any
 * existing query params in the current url
 *
 /************/
export const appendParams = obj => {
  if (!process.client) return null

  const url = new URL(window?.location.href)

  if (!url) return null
  const queryParams = buildFilterParams(obj)

  for (const [key, value] of Object.entries(queryParams)) {
    url.searchParams.delete(key)

    if (value !== '') {
      url.searchParams.append(key, value)
    }
  }

  history.replaceState({}, null, url)
}

/***********
 * buildFilterParams()
 *
 * takes an object and builds an individual query string
 * for all values in the object to append to the current URl
 *
 /************/
const buildFilterParams = filters => {
  return Object.keys(filters).reduce((acc, key) => {
    const paramValue =
      typeof filters[key] !== 'object' ? filters[key] : filters[key].join(',')

    const obj = { ...acc }
    obj[key] = encodeURIComponent(paramValue)

    return obj
  }, {})
}

/***********
 * findProductsInBothArrays()
 *
 * Compares two arrays and returns only the values inside both
 *
 /************/
const findProductsInBothArrays = (first, second) => {
  return second.filter(x =>
    first.some(y => {
      if (!x || !y) return false

      const xProd = x.node ?? x
      const yProd = y.node ?? y

      return xProd._meta?.id === yProd._meta?.id
    })
  )
}
