import { getShopifyProduct } from "../helpers"

export const sortProducts = ({sortBy, products, locale, pageType, sortCollectionManually}) => {
  if (sortBy === 'latest') return sortByLatest(locale, products)
  if (sortBy === 'oldest') return sortByOldest(locale, products)
  if (sortBy === 'price_high_low') return sortByPriceHighLow(locale, products)
  if (sortBy === 'price_low_high') return sortByPriceLowHigh(locale, products)

  if (sortBy === 'relevance' && !sortCollectionManually) {
    return sortByImportance(locale, products, pageType)
  }

  return products
}

const sortByImportance = (locale, products, pageType) => {
  return sortByType({
    type: 'importance', 
    direction: 'desc',  
    locale, 
    products, 
    pageType
  })
}

const sortByLatest = (locale, products) => {
  return sortByType({
    type: 'date', 
    direction: 'desc', 
    locale, 
    products,
  })
}

const sortByOldest = (locale, products) => {
  return sortByType({
    type: 'date', 
    direction: 'asc', 
    locale, 
    products,
  })
}

const sortByPriceHighLow = (locale, products) => {
  return sortByType({
    type: 'price', 
    direction: 'desc', 
    locale, 
    products 
  })
}

const sortByPriceLowHigh = (locale, products) => {
  return sortByType({
    type: 'price', 
    direction: 'asc', 
    locale, 
    products 
  })
}


const sortByType = ({type, direction, locale, products, pageType}) => {
  return products.sort((a, b) => {
    const aProduct = a.node ?? a
    const bProduct = b.node ?? b

    const aField = findField({item: aProduct, type, pageType, locale})
    const bField = findField({item: bProduct, type, pageType, locale})
    
    if (direction == 'desc') {
      if (aField > bField) return -1
      if (aField < bField) return 1
    }

    if (direction == 'asc') {
      if (aField > bField) return 1
      if (aField < bField) return -1
    }

    return 0
  })
}

const findField = ({item, type, pageType, locale}) => {
  if (type == 'importance') {
    return getImportance(item, pageType) ?? 0
  }

  const shopifyProduct = getShopifyProduct(item, locale)
  
  if (type == 'date') {
    return new Date(shopifyProduct.published_at).getTime() ?? 0
  }

  if (type == 'price') { 
    return parseFloat(shopifyProduct.variants[0].price) ?? 0
  }
}

const getImportance = (product, pageType) => {
  if (pageType === 'shopCategory') {
    return product.shop_category_importance ?? product.importance
  }

  if (pageType === 'product_type') {
    return product.product_type_importance ?? product.importance
  }

  if (pageType === 'brand') {
    return product.brand_importance ?? product.importance
  }
  
  return product.importance
}
