export class MyShelfService {
  constructor(locale) {
    this.script =
      locale === 'en-ca'
        ? process.env.NUXT_ENV_MYSHELF_SRC_CA
        : process.env.NUXT_ENV_MYSHELF_SRC_US
  }

  async installScript() {
    const parent = document.head || document.body

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = this.script

    parent.appendChild(script)

    return script
  }
}
