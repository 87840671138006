export const getLocaleOnClient = hostname => {
  return hostname === process.env.NUXT_ENV_DOMAIN_US ? 'en-us' : 'en-ca'
}


export const getLocaleOnServer = (headers) => {
  if (headers['x-forwarded-host']) {
    const host = headers['x-forwarded-host'].split(', ')[0]
    return host === process.env.NUXT_ENV_DOMAIN_US ? 'en-us' : 'en-ca'
  }

  if (headers['authority'] || headers[':authority']) {
    const host = headers['authority'] || headers[':authority']
    return host === process.env.NUXT_ENV_DOMAIN_US ? 'en-us' : 'en-ca'
  }

  return headers.host === process.env.NUXT_ENV_DOMAIN_US ? 'en-us' : 'en-ca'
}
