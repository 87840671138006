import { sanitizeShopifyId } from "@/helpers";
import axios from 'axios'

export class LiveChatService {
  constructor(user, appId, $service) {
    this.user = user
    this.appId = appId
    this.liveChat = $service;
  }

  async boot() {
    const { data } = this.user;

    if (!data) {
      this.liveChat("boot", {});
      return;
    }

    const user_id = sanitizeShopifyId("Customer", data.id)
    const userHash = await this.fetchUserHash(user_id, data.email)
    const date = this.unixDate(data.createdAt);
    
    const payload = {
      app_id: this.appId,
      user_id: user_id,
      name: data.name,
      created_at: date,
    }

    if (userHash) {
      payload.email = data.email
      payload.user_hash = userHash
    }

    this.liveChat("boot", payload);
  }

  show() {
    this.liveChat("show");
  }

  hide() {
    this.liveChat("hide");
  }

  update(data = {}) {
    this.liveChat("update", data);
  }

  async handleLogin() {
    const { data } = this.user;

    if (!data) return null;

    const date = this.unixDate(data.createdAt);
    const userHash = await this.fetchUserHash(data.email)

    this.update({
      user_id: sanitizeShopifyId("Customer", data.id),
      name: `${data.firstName} ${data.lastName}`,
      email: data.email,
      user_hash: userHash,
      created_at: date
    });
  }

  handleLogOut() {
    try {
      this.liveChat("shutdown");
      this.liveChat("boot", {});
    } catch (error) {
      console.log(error)
    }
  }

  unixDate(str) {
    const timestamp = Date.parse(str.split("T")[0]);

    return Math.floor(timestamp / 1000);
  }

  async fetchUserHash(user_id, email) {
    try {
      const { data } = await axios.post(
        'https://phpstack-490411-2009062.cloudwaysapps.com/intercom/hash?store=gee_beauty', 
        { user_id, email })
      return data.user_hash 
    } catch (error) {

      console.log(error)
      return null
    }
  } 
}
