import gql from 'graphql-tag';

export const fetchProductByHandle = gql`
  query query($handle: String!) {
    productByHandle(handle: $handle) {
      ... on Product {
        availableForSale
        images(first: 1) {
          edges {
            node {
              src: originalSrc
              alt: altText
            }
          }
        }
        options {
          name
          values
        }
        variants(first: 25) {
          edges {
            node {
              id
              title
              price {
                amount
              }
              compareAtPrice {
                amount
              }
              availableForSale
              image {
                src: originalSrc
                alt: altText
              }
              sku
              hexCode: metafield(key: "variant_hex_code", namespace: "my_fields") {
                value
              }
              sellingPlanAllocations(first: 25){
                edges {
                  node {
                    priceAdjustments{
                      price{
                        amount
                      }
                    }
                    sellingPlan{
                      id
                      name
                      description
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const findCollectionProductsByHandle = gql`
  query ($handle: String!, $cursor: String){
    collectionByHandle(handle: $handle) {
      products(first: 250, after: $cursor) {
        pageInfo {
          hasNextPage
        }
        edges {
          cursor
          node {
            id
          }
        }
      }
    }
  }
`;
