import Cookie from 'js-cookie'
import {
  createCheckout,
  findCheckout,
  checkoutLineItemsReplace,
  applyDiscountCode,
} from '@/shopify/checkout'
import { findCollectionProductsByHandle } from '@/shopify/products'
export default class ShopifyApolloService {
  constructor(apolloProvider) {
    this.client = null
    this.apolloProvider = apolloProvider
  }

  async initClient(locale) {
    this.locale = locale

    this.client =
      this.locale === 'en-ca'
        ? this.apolloProvider.clients.shopifyCA
        : this.apolloProvider.clients.shopifyUS
  }

  client() {
    return this.client
  }

  async initCheckout() {
    let checkoutId = Cookie.get(`gee_checkout_${this.locale}`)

    if (!checkoutId) return this.createCheckout()

    const { data } = await this.client.query({
      query: findCheckout,
      variables: { checkoutId },
    })

    const checkout = data?.node

    // if checkout exists but was completed, lets return a new checkout
    if (!checkout || checkout.completedAt) return this.createCheckout()

    return checkout
  }

  async createCheckout() {
    Cookie.remove(`gee_checkout_${this.locale}`)

    const { data } = await this.client.mutate({
      mutation: createCheckout,
      variables: { input: {} },
    })

    const { checkout } = data.checkoutCreate

    if (process.client) {
      localStorage.removeItem(`gee_cart_${this.locale}`)
    }

    Cookie.set(`gee_checkout_${this.locale}`, checkout.id)

    return checkout
  }

  async updateCheckout(checkout, newLineItems) {
    try {
      if (!checkout) {
        const newCheckout = this.createCheckout()

        return this.checkoutLineItemReplace({
          lineItems: newLineItems,
          checkoutId: newCheckout.id,
        })
      }

      //newLineItems[0].sellingPlanId = "Z2lkOi8vc2hvcGlmeS9TZWxsaW5nUGxhbi8xMDU3NDU2MTg2";

      return this.checkoutLineItemReplace({
        lineItems: newLineItems,
        checkoutId: checkout.id,
      })
    } catch (error) {
      console.log(error)
      alert('Sorry something went wrong')
    }
  }

  async checkoutLineItemReplace(variables) {
    const { data } = await this.client.mutate({
      mutation: checkoutLineItemsReplace,
      variables: variables,
    })

    return data.checkoutLineItemsReplace.checkout
  }

  async applyDiscount(discountCode, checkoutId) {
    try {
      const { data } = await this.client.mutate({
        mutation: applyDiscountCode,
        variables: { discountCode, checkoutId },
      })

      console.log(discountCode)

      const { checkoutDiscountCodeApplyV2 } = data

      console.log(checkoutDiscountCodeApplyV2)

      if (checkoutDiscountCodeApplyV2.checkoutUserErrors.length) {
        console.log(checkoutDiscountCodeApplyV2.checkoutUserErrors)
        throw new Error(checkoutDiscountCodeApplyV2.checkoutUserErrors)
      }

      return checkoutDiscountCodeApplyV2.checkout
    } catch (error) {
      console.log(error)
      throw new Error(
        ' Something went wrong adding your discount to the checkout.'
      )
    }
  }

  async getDiscountProductIds() {
    const products = await this.fetchAllDiscountProducts()

    return products.map(item => item.node.id)
  }

  async fetchAllDiscountProducts() {
    let hasNextPage = true
    let lastCursor = null
    let products = []

    do {
      const { data } = await this.client.query({
        query: findCollectionProductsByHandle,
        variables: {
          handle: 'all-w-exclusions-for-blackfriday-sale',
          cursor: lastCursor,
        },
      })

      const { edges, pageInfo } = data.collectionByHandle.products
      hasNextPage = pageInfo.hasNextPage
      lastCursor = [...edges].pop().cursor
      products = [...products, ...edges]
    } while (hasNextPage)
    return products
  }
}
