import algoliasearch from 'algoliasearch'

export const client = algoliasearch(
  // process.env.NUXT_ENV_ALGOLIA_APP_ID,
  'XFOZ4YD0HE',
  '71e50454d1ba50e70dab97341e3431cf'
  // process.env.NUXT_ENV_ALGOLIA_ADMIN_API_KEY
)

export const Algolia = client.initIndex('gee_beauty')

export class PredictiveSearch {
  constructor(options) {
    Object.assign(this, options)

    this.client = client

    this.querySuggestionsIndex = this.client.initIndex(
      'gee_beauty_query_suggestions'
    )
  }

  search = async searchTerm => {
    if (!searchTerm) return []

    const { hits } = await this.querySuggestionsIndex.search(searchTerm)

    // const suggestions = hits.filter(({ query }) => {
    //   query.includes(searchTerm)
    // });
    const suggestions = hits

    if (!suggestions.length) return []

    return suggestions
  }
}
