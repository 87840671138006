import { prismicClient } from '../plugins/apollo/prismic'

import { fetchBeautyMarksData } from '../prismic/queries'

export class SmileService {
  constructor(axios, shopify, smileToken) {
    this.$axios = axios

    this.$axios.defaults.baseURL = 'https://api.smile.io/v1'
    this.$axios.defaults.headers = {
      Authorization: `Bearer ${smileToken}`,
    }
  }

  async fetchCustomerData(email) {
    if (!email) return null

    const { data } = await this.$axios.get(`/customers?email=${email}`)

    if (!data.customers) return null

    return data.customers[0]
  }

  async fetchBeautyMarksContent() {
    try {
      const { data } = await prismicClient.query({
        query: fetchBeautyMarksData,
      })

      const { edges } = data.allBeauty_markss

      if (!edges) return null

      return edges[0].node
    } catch (err) {
      console.log(err)
      return null
    }
  }

  async fetchRewards(shopify_products) {
    try {
      const { data } = await this.$axios.get('/points_products')

      if (!data.points_products) return []

      let rewards = data.points_products

      if (shopify_products) {
        rewards = this.attachProductsToRewards(
          data.points_products,
          shopify_products
        )
      }

      return rewards
    } catch (error) {
      console.log(error)
      return []
    }
  }

  attachProductsToRewards(rewards, products) {
    return rewards.map(reward => {
      const reward_product = products.find(
        ({ reward_id }) => reward_id === reward.id
      )

      if (!reward_product || !reward_product) return reward

      return {
        ...reward,
        product: reward_product.shopify_product,
      }
    })
  }

  nextReward(rewards, points_balance) {
    return rewards.filter(
      ({ points_price }) => points_price > points_balance
    )[0]
  }

  async fetchPointsTransactions(customer_id) {
    const { data } = await this.$axios.get(
      `/points_transactions?customer_id=${customer_id}`
    )

    return data.points_transactions
  }

  getTransactionTitle(item) {
    return item.point_change < 0 ? item.id : item.description
  }

  async purchasePointsProduct(customer_id, points_product_id, points_to_spend) {
    try {
      const { data } = await this.$axios.post(
        `/points_products/${points_product_id}/purchase`,
        { customer_id, points_to_spend }
      )

      return data.points_purchase.reward_fulfillment
    } catch (error) {
      return error
    }
  }

  productForCart(product) {
    return {
      isReward: true,
      title: product.title,
      _meta: product._meta,
      id: product.id,
      image: { src: product.image.src },
      product_type: { title: product.product_type },
      variantId: Buffer.from(
        `gid://shopify/ProductVariant/${product.variants[0].id}`
      ).toString('base64'),
      brand: { title: product.vendor },
      price: product.variants[0].price,
      quantity: 1,
    }
  }

  calculatePointsFromCartItems(lineItems) {
    const subTotal = lineItems.reduce((acc, curr) => {
      if (curr.isGiftCard) return acc

      return acc + parseFloat(curr.salePrice ? curr.salePrice : curr.price)
    }, 0)

    return Math.floor(subTotal)
  }
}
