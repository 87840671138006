/***
    Takes an array of collections and find if it contains our UID.
*/
export const findUIDInCollection = (uid, arr) => {
  if (!arr) return null

  const arrayHasUid = arr.filter(({ collection }) => {
    if (!collection || collection.__typename !== 'Collection') {
      return false
    }

    return collection._meta.uid === uid
  })

  if (!arrayHasUid.length) return null

  return arr
}

// maps over the ShopMenu Categories array looking for the uid
export const findUIDInShopMenu = (uid, arr, key) => {
  if (!arr) return null

  // if element has the UID, lets return some info about the element.
  const arrayHasUid = arr
    .map(ele => {
      const hasUID = findUIDInCollection(uid, ele[key])

      if (!hasUID) return null

      return {
        type: ele.type,
        title: ele.title,
        _meta: ele._meta,
        highlights: ele.highlights ?? null,
        productTypes: ele.productTypes ?? null,
        shopCategory: ele.shopCategory ?? null,
      }
    })
    .filter(v => v !== null)

  if (!arrayHasUid.length) return null

  return arrayHasUid[0]
}

/**
findAncestor()
@returns Object

loops over this.collectionKeys, searching the baseArray['highlights'] and baseArray['productTypes']['featured_collections']
for a collection with the UID === to the UID variable we pass in.
**/
export const findAncestor = (uid, baseArray, collectionKeys) => {
  let found = null

  for (const key of collectionKeys) {
    if (typeof key === 'string') {
      found = findUIDInShopMenu(uid, baseArray, key)
      if (found) break
    }

    if (Array.isArray(key)) {
      const foundItem = baseArray
        .map(ele => {
          const nestedArray = ele[key[0]]

          return findUIDInShopMenu(uid, nestedArray, key[1])
        })
        .filter(v => v !== null)

      if (!foundItem.length) continue
      found = foundItem[0]
      break
    }
  }

  return found
}

/**
findCollectionSiblings()
@returns Object

loops over this.collectionKeys, searching the this.shopCatData['highlights'] and this.shopCatData['productTypes']['featured_collections']
for a collection with the UID === to the UID variable we pass in.
*/

export const findCollectionSiblings = (uid, shopCat, collectionKeys) => {
  let found = null

  for (let key of collectionKeys) {
    if (typeof key === 'string') {
      if (!shopCat[key]) continue

      found = findUIDInCollection(uid, shopCat[key])

      if (found) break
    }
    if (Array.isArray(key)) {
      if (!shopCat[key[0]]) break

      const foundItem = shopCat[key[0]]
        .map(ele => {
          const nestedArray = ele[key[1]]
          return findUIDInCollection(uid, nestedArray)
        })
        .filter(v => v !== null)

      if (!foundItem.length) continue

      found = foundItem[0]
      break
    }
  }

  // map over all the items in found, return its collection object,
  // then filter out the collection that matches the current page we're on
  const removeUidFromFoundArray = found
    .map(item => item.collection)
    .filter(collection => {
      if (!collection || !collection._meta) return false

      return collection._meta.uid !== uid
    })

  if (!removeUidFromFoundArray.length) return null

  return removeUidFromFoundArray
}
