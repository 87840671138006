import { getShopifyProduct } from '../helpers'
import { prismicClient } from '../plugins/apollo/prismic'

import {
  fetchBrandDocumentId,
  fetchBrandDocument,
  fetchBrandProducts,
  fetchCollectionDocument,
  fetchCollectionProducts,
  fetchProductTypeDocument,
  fetchProductTypeProducts,
  fetchShopCategoryDocument,
  fetchShopLandingDocument,
  fetchAllProducts,
  searchProducts,
} from '../prismic/queries/landingPage'

import { Algolia } from './AlgoliaService'

/*********************
fetchLandingPageData

accepts routePath from Nuxt, then fetches the required data that we need from prismic.
*********************/
export const fetchLandingPageData = async (routePath, locale) => {
  const { type, query, handle, shopCategory, productQuery } =
    getLandingPageInfo(routePath)

  if (type === 'search') {
    return {
      type,
      handle,
      productQuery,
      shopCategory,
      model: null,
    }
  }

  const payload = { query: query }

  if (type !== 'shop') payload.variables = { uid: handle }

  const { data } = await prismicClient.query(payload)

  const { edges } = getGQLRespObject(data)

  let hideFromLocale = false;
  if(edges.length) {
    hideFromLocale = edges[0].node.hide_from_locale == (locale === 'en-ca' ? 'Canada' : 'US');
  }

  if (hideFromLocale || !edges.length) {
    throw {
      statusCode: 404,
      message: `We couldn't find what you were looking for.`,
    }
  }

  return {
    type,
    handle,
    productQuery,
    shopCategory,
    model: edges[0],
  }
}

/*********************
getLandingPageInfo

inspects an array of url parts and returns
information about the landing page the user is trying acess

determines:
1. what prismic gql query needs to be used
2. the type of landing page we're on e.g. collection, product type, shop category,
3. provides the uid we need to query prismic with e.g. "face", "dr-barbara-sturm"

*********************/
export const getLandingPageInfo = urlPath => {
  const { routeInfo, params } = explodeLandingPageRoute(urlPath)

  // the /shop -- all products landing page
  if (!routeInfo.length) {
    return {
      query: fetchShopLandingDocument,
      productQuery: fetchAllProducts,
      handle: 'shop', // the specific brand to query
      shopCategory: null,
      type: 'shop',
    }
  }

  // a single brand landing page or "all brands" landings page
  if ('brands' === routeInfo[0]) {
    return {
      query: fetchBrandDocument,
      productQuery: fetchBrandProducts,
      handle: routeInfo[1], // the specific brand to query
      shopCategory: null,
      type: 'brand',
    }
  }

  // /shop/collections
  if ('collections' === routeInfo[0]) {
    return {
      query: fetchCollectionDocument,
      productQuery: fetchCollectionProducts,
      handle: routeInfo[1],
      shopCategory: null,
      type: 'collections',
    }
  }

  if (routeInfo[0].includes('search')) {
    return {
      query: null,
      productQuery: null,
      handle: getSearchQuery(params),
      type: 'search',
      shopCategory: null,
    }
  }

  // /shop/makeup, /shop/skincare
  if (!routeInfo[1]) {
    return {
      query: fetchShopCategoryDocument,
      productQuery: fetchProductTypeProducts,
      handle: routeInfo[0],
      shopCategory: routeInfo[0],
      type: 'shopCategory',
    }
  }

  // /shop/makeup/lips
  // /shop/skincare/cleansers
  return {
    query: fetchProductTypeDocument,
    productQuery: fetchProductTypeProducts,
    handle: routeInfo[1],
    shopCategory: routeInfo[0],
    type: 'product_type',
  }
}

export const handlePrismicQueries = async (query, queryVars, type) => {
  let shouldSendRequest = true
  const data = {
    totalCount: 0,
    pageInfo: {},
    edges: [],
  }

  do {
    const variables = { ...queryVars }
    variables.cursor = data.pageInfo.endCursor ?? ''

    const { totalCount, pageInfo, edges } = await handleQuery(query, variables)
    data.pageInfo = pageInfo
    data.totalCount = totalCount
    data.edges = [...data.edges, findProductsFromEdges(edges, type)].flat()

    shouldSendRequest = pageInfo.hasNextPage
  } while (shouldSendRequest)

  return data
}

const handleQuery = async (query, variables) => {
  const { data } = await prismicClient.query({ query, variables })

  const { totalCount, pageInfo, edges } = getGQLRespObject(data)

  if (!variables.hasNoProducts && !edges.length) {
    throw {
      statusCode: 404,
      message: `We couldn't find what you were looking for.`,
    }
  }

  return { edges, totalCount, pageInfo }
}

/*********************
fetchProducts

fetch products on the client side

accepts properties from Nuxt and Vue, then fetches the required data
that we need from prismic.
*********************/
export const fetchProducts = async ({
  store,
  handle,
  numResults,
  type,
  query,
  cursor,
  hasNoProducts
}) => {
  if (type === 'search') {
    const results = await Algolia.search(handle, { hitsPerPage: 1000 })

    return {
      edges: results.hits,
      totalCouts: results.hits.length,
      pageInfo: null,
    }
  }

  // if Type is either product_type or brand,
  // we need to find its document id (different from UID)
  // otherwise the handle is fine
  const uid = ['product_type', 'brand'].includes(type)
    ? await findDocumentId(type, handle, store)
    : handle

  if (type !== 'shopCategory') {
    const variables = { cursor, numResults: 5000, hasNoProducts: hasNoProducts }
    if (uid !== 'shop') variables.uid = uid

    return handlePrismicQueries(query, variables, type)
  }

  // if we're on a shop_category page, we need to first find
  // all the product types inside the shop_category
  const categoryProductTypes = findProductTypesInCategory(handle, store)

  return fetchShopCategoryProducts(categoryProductTypes, query, cursor)
}

export const explodeVariants = (products, locale) => {
  const variants = products
    .map(product => {
      const shopifyProduct = getShopifyProduct(product, locale)

      const variants = shopifyProduct.variants.map(variant => {
        const shortLocale = locale.replace('en-', '')

        const image = shopifyProduct.images.find(
          image => image.id === variant.image_id
        )

        return {
          ...product,
          variantTitle: variant.title,
          variantId: variant.id,
          explodeVariants: true,
          image: image,
          price: variant.price,
          [`shopify_product_${shortLocale}`]: {
            ...product[`shopify_product_${shortLocale}`],
            image: image,
          },
        }
      })

      return variants
    })
    .flat()

  return variants
}

const findProductTypesInCategory = (handle, store) => {
  const { shop } = store.state.header.links
  const shopCat = shop.filter(cat => cat.type === handle)

  if (!shopCat.length) {
    throw { statusCode: 404, message: "Shop Category doesn't exist" }
  }

  const productTypesInCategory = shopCat[0].productTypes.map(({ _meta }) => {
    return { id: _meta.id, uid: _meta.uid }
  })

  if (!productTypesInCategory.length) {
    throw {
      statusCode: 404,
      message:
        'Make sure to add this Shop Category to at least one Product Type.',
    }
  }

  return productTypesInCategory
}

const fetchShopCategoryProducts = async (productTypes, query, cursor) => {
  const productData = await Promise.all(
    // fetch the product
    productTypes.map(async prodType => {
      const variables = {
        uid: prodType.id,
        cursor,
        numResults: 5000,
      }

      return await handlePrismicQueries(query, variables, 'product_type')
    })
  )

  // collect the products and flatten the array, then transform it into one object that fits the expected shape that our front-end needs.
  const data = collectShopCategoryProducts(productData)

  if (!data.edges.length) {
    throw { statusCode: 404, message: 'No products found' }
  }

  return data
}

const collectShopCategoryProducts = productData => {
  const accumulator = {
    totalCount: 0,
    edges: [],
    pageInfo: {
      hasNextPage: false,
    },
  }

  return productData.reduce(
    (obj, acc) => ({
      edges: [...acc.edges, ...obj.edges],
      totalCount: (acc.totalCount += obj.totalCount),
      pageInfo: {
        hasNextPage: obj.pageInfo.hasNextPage ?? acc.pageInfo.hasNextPage,
      },
    }),
    accumulator
  )
}

// when fetching brands or product type we query products directly --  i.e. 'edges' is an array of products
// when fetching collections or concerns, the products are buried inside of Prismic's group structure -- i.e. edges[0].node.produdcts[0].product
export const findProductsFromEdges = (edges, type) => {
  if (['product_type', 'brand', 'shop', 'search'].includes(type)) {
    return edges
  }

  return edges[0].node.products.map(prod => {
    return {
      ...prod.product,
      canadian_variant_associated_sku: prod.canadian_variant_associated_sku,
      us_variant_associated_sku: prod.us_variant_associated_sku,
    }
  })
}

/*********************
findDocumentId

We need the Document ID to query Products by Brand or Product Type.
https://prismic.io/docs/technologies/query-by-a-content-relationship-or-link-field-graphql#example-query
*********************/
export const findDocumentId = async (type, uid, store) => {
  if ('product_type' === type) {
    const productType = store.state.header.links.shop
      .map(cat => cat.productTypes)
      .flat()
      .find(prodType => prodType._meta.uid === uid)

    if (!productType) {
      throw {
        statusCode: 404,
        message: `We couldn't find what you were looking for.`,
      }
    }

    return productType._meta.id
  }

  const { data } = await prismicClient.query({
    query: fetchBrandDocumentId,
    variables: { uid: uid },
  })

  return data.allBrands.edges[0].node._meta.id
}

/*********************
explodeLandingPageRoute

takes a reoute like "/shop/makeup/face" and splits
it into an array of url parts.

this lets us examine what section of the site we're on,
and query Prismic accordingly.
*********************/
export const explodeLandingPageRoute = urlPath => {
  const pathParts = urlPath.split('?')

  const routeInfo = pathParts[0]
    .replace('/shop', '')
    .split('/')
    .filter(val => val != '')

  const params = pathParts[1] ? pathParts[1].split('&') : null

  return {
    routeInfo,
    params,
  }
}

const getSearchQuery = params => {
  const searchParam = params.find(item => item.includes('q='))
  const regex = /(%20|%2520)+/g
  return searchParam.split('q=')[1].replace(regex, ' ')
}

/*********************
prismic returns object based on query, e.g.:
data {
  allProducts {}
}

data {
  allConcerns {}
}

This function takes that first data object and returns
whatever is the first key inside of it.

This is used on the shop landing page to handle our
many different kinds of queries.

e.g. allProducts, allConcerns, allCollections, etc.

returns an object
*********************/
export const getGQLRespObject = obj => obj[Object.keys(obj)[0]]
