import axios from 'axios'
export class KlaviyoService {
  constructor(publicKey) {
    this.SCRIPTS = [
      `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${publicKey}`,
      '//www.klaviyo.com/media/js/public/klaviyo_subscribe.js',
    ]

    this.storeId = process.env.NUXT_ENV_KLAVIYO_LIST_ID

    this.initKlaviyo()
  }

  async initKlaviyo() {
    await this.loadScripts()

    this._learnq = window._learnq || []
  }

  async loadScripts() {
    const scripts = this.SCRIPTS.map(src => {
      return new Promise(async (resolve, reject) => {
        try {
          const script = await this.installScript(src)
          script.addEventListener('load', () => resolve(true))
        } catch (error) {
          reject(error)
        }
      })
    })

    return await Promise.all(scripts)
  }

  async installScript(scriptSrc) {
    const parent = document.head || document.body

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = scriptSrc

    parent.appendChild(script)

    return script
  }

  identifyCustomer(email) {
    if (!email || !this._learnq) return null

    this._learnq.push(['identify', { $email: email }])
  }

  trackProductImpression(product) {
    if (!product || !this._learnq) return null

    this._learnq.push(['track', 'Viewed Product', product])

    this._learnq.push([
      'trackViewedItem',
      {
        Title: product.Name,
        productId: product.ProductID,
        Categories: product.Categories,
        ImageUrl: product.ImageURL,
        Url: product.URL,
        Metadata: {
          Brand: product.Brand,
          Price: product.Price,
          CompareAtPrice: product.CompareAtPrice,
        },
      },
    ])
  }

  checkoutStarted(cart) {
    _learnq.push(['track', 'Started Checkout', this.prepareCheckout(cart)])
  }

  prepareCheckout(cart) {
    const itemNames = cart.items.map(item => item.title)
    const categories = cart.items.map(item => item.product_type)

    const items = cart.items.map(item => {
      return {
        ProductID: atob(item.variantId),
        SKU: item.sku,
        ProductName: item.title,
        Quantity: item.quantity,
        ItemPrice: item.price,
        RowTotal: item.price * quantity,
        ProductURL: 'tk',
        ImageURL: item.image.src,
        ProductCategories: [item.category],
      }
    })

    return {
      $event_id: '1000123_1387299423',
      $value: cart.checkout.totalPrice,
      ItemNames: itemNames,
      CheckoutURL: cart.checkoutUrl,
      Categories: categories,
      Items: items, // [{
      //      "ProductID": "1111",
      //      "SKU": "WINNIEPOOH",
      //      "ProductName": "Winnie the Pooh",
      //      "Quantity": 1,
      //      "ItemPrice": 9.99,
      //      "RowTotal": 9.99,
      //      "ProductURL": "http://www.example.com/path/to/product",
      //      "ImageURL": "http://www.example.com/path/to/product/image.png",
      //      "ProductCategories": ["Fiction", "Children"]
      //    },
      //    {
      //      "ProductID": "1112",
      //      "SKU": "TALEOFTWO",
      //      "ProductName": "A Tale of Two Cities",
      //      "Quantity": 1,
      //      "ItemPrice": 19.99,
      //      "RowTotal": 19.99,
      //      "ProductURL": "http://www.example.com/path/to/product2",
      //      "ImageURL": "http://www.example.com/path/to/product/image2.png",
      //      "ProductCategories": ["Fiction", "Classics"]
      //    }
      //  ]
    }
  }

  async addToDefaultList(locale, email) {
    const storeId =
      locale === 'en-ca' ? 'gee-beauty-toronto' : 'gee-beauty-bal-harbour'

    const { data } = await axios.post(
      `https://phpstack-490411-2009062.cloudwaysapps.com/klaviyo?store=${storeId}`,
      { email: email }
    )

    return data
  }
}
