import { prismicClient } from '../plugins/apollo/prismic'

import { fetchAllProductTypes } from '../prismic/queries/global'

export const recursivelyFetchAllProductTypes = async (locale, cursor = '') => {
  try {
    const { allProduct_types } = (
      await prismicClient.query({
        query: fetchAllProductTypes,
        variables: { cursor },
      })
    ).data

    const { edges, pageInfo } = allProduct_types

    if (!pageInfo.hasNextPage) {
      return edges
    }

    const newProductTypes = await recursivelyFetchAllProductTypes(
      locale,
      pageInfo.endCursor
    )

    return [...edges, ...newProductTypes]
  } catch (error) {
    console.log(error)
    console.log(error?.networkError?.result)
  }
}

// if hide from locale is equal to 'Canada' and the locale is 'en-ca',
// lets hide that product type from the menu.
export const filterHiddenItems = (locale, edges) => {
  return edges.filter(el => el.hide_from_locale !== getLocaleText(locale))
}

const getLocaleText = locale => (locale === 'en-ca' ? 'Canada' : 'US')
